// Required dependencies

import 'jquery';
import 'bootstrap';

window.Nette = require('live-form-validation');
window.Nette.initOnLoad();

import 'nette.ajax.js';

import "../sass/main.scss";

import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';

import '../img/fosco-alma.jpg';
import '../img/products/1.png';
import '../img/products/2.png';
import '../img/products/3.png';
import '../img/products/4.png';

$(function () {

    $.nette.init();

    $('#closeCart').on('click', function (e) {
        console.log('click');
        $('#exampleModal').modal('hide');
    });
});